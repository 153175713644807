import React, { useState } from "react"
import { firestore } from "../../firebase"
import Popup from "../Sign/Popup"

import { useAuth } from "../../context/AuthContext"
import { IconCopy, IconView, IconNoView } from "../../components/Icons"

import { telegram } from "../../utils/telegram"

export default function ComfyUI() {
  const { user } = useAuth()
  const [showPopup, setShowPopup] = useState(false)

  const generateKey = () => {
    let key = ""
    while (key.length < 40) {
      key += Math.random().toString(36).substr(2)
    }
    return key.substr(0, 40)
  }

  const createKey = async (keyName = "api_key") => {
    try {
      const randomKey = generateKey()
      await firestore
        .collection("users")
        .doc(user.id)
        .update({
          [keyName]:
            keyName === "comfyui_key" ? "cu_" + randomKey : "ap_" + randomKey,
        })
    } catch (error) {
      telegram(`API.js, error creating the key:` + error)
    }
  }

  const deleteKey = async (keyName = "api_key") => {
    try {
      await firestore
        .collection("users")
        .doc(user.id)
        .update({
          [keyName]: null,
        })
    } catch (error) {
      telegram(`API.js, error deleting the key:` + error)
    }
  }

  const [showapikey, setShowapikey] = React.useState(false)
  const [copiedToken, setCopiedToken] = React.useState(false)

  const handleCopyToken = (text) => {
    copyToClipboard(text)
    setCopiedToken(true)
    setTimeout(() => setCopiedToken(false), 2000)
  }

  function copyToClipboard(text) {
    const dummy = document.createElement("textarea")
    dummy.style.position = "absolute"
    dummy.style.left = "-9999px"
    document.body.appendChild(dummy)
    dummy.value = text
    dummy.select()
    document.execCommand("copy")
    document.body.removeChild(dummy)
  }

  return (
    <div className="w-full flex flex-col items-center pt-12 mb-28 lg:pt-18 bg-bg-color text-white flex-grow">
      <div className="w-10/12 2xl:w-8/12 overflow-x-auto flex flex-col">
        <div>
          <p className="text-3xl font-bold mt-8 mb-4">Upscale in ComfyUI</p>
          {/* image as an example */}
          <img
            src="/images/comfyui_example.jpeg"
            alt="comfyui"
            className="w-10/12"
          />
          <p className="text-2xl font-bold mt-12 mb-4">
            1. Install ComfyUI node
          </p>
          Open ComfyUI Manager, search for Clarity AI, and install the node.
          Alternatively install the node manually:
          <div className="my-4 text-sm bg-neutral-800 p-4 relative">
            <div>cd custom_nodes</div>
            <div>git clone https://github.com/philz1337x/ComfyUI-ClarityAI</div>
          </div>
          <div>
            <p className="text-2xl font-bold mt-12 mb-4">
              2. Create your API key
            </p>

            <div className="my-4 text-sm bg-neutral-800 p-4 relative">
              <pre>
                {user?.api_key
                  ? showapikey
                    ? user?.api_key
                    : user?.api_key?.slice(0, 6) +
                      "**********************************"
                  : "Create a token first"}
              </pre>

              <button
                onClick={() => setShowapikey((showapikey) => !showapikey)}
                className="absolute top-2 right-14 text-xs bg-white text-gray-700 hover:text-gray-500 p-1 rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {showapikey ? <IconNoView /> : <IconView />}
              </button>
              <button
                onClick={() => handleCopyToken(user.api_key)}
                className="absolute top-2 right-2 text-xs bg-white text-gray-700 hover:text-gray-500 p-1 rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {copiedToken && (
                  <span className="absolute z-10 top-full right-0 mt-2 bg-black text-white py-1 px-2 rounded">
                    Copied!
                  </span>
                )}
                <IconCopy />
              </button>
            </div>

            {user && user?.api_key ? (
              <button
                onClick={() => deleteKey("api_key")}
                className="h-12 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-800"
              >
                Delete
              </button>
            ) : (
              <button
                onClick={
                  user ? () => createKey("api_key") : () => setShowPopup(true)
                }
                className="h-12 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-800 focus:outline-none focus:shadow-outline-red"
              >
                Create Token
              </button>
            )}
          </div>
          <p className="text-2xl font-bold mt-12 mb-4">
            3. Add the API key to the node
          </p>
          <div>
            - Add the API key to the environment variable "`CAI_API_KEY`"
          </div>
          <div>
            - Alternatively, you can write your API key to a
            "`cai_platform_key.txt`" text file in the ComfyUI-ClarityAI folder.
          </div>
          <div>
            - Not recommended: You can also use and/or override the above by
            entering your API key in the '`api_key_override`' field. But be
            careful to delete the api_key_override when sharing your workflow.
          </div>
        </div>
        <Popup show={showPopup} onClose={() => setShowPopup(false)} />
      </div>
    </div>
  )
}
