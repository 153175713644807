import React, { useState } from "react"
import { useAuth } from "../context/AuthContext"
import { FaTwitter } from "react-icons/fa"
import { telegram } from "../utils/telegram"

const SupportOption = ({ title, onClick, icon: Icon }) => (
  <div
    className="bg-neutral-800 border border-neutral-700 shadow-md rounded-lg p-8 cursor-pointer hover:bg-neutral-700 transition duration-300 flex flex-col items-center justify-center md:h-64"
    onClick={onClick}
  >
    <h3 className="text-2xl font-semibold mb-6 text-white">{title}</h3>
    <Icon className="text-6xl text-neutral-300" />
  </div>
)

function Support() {
  const { user } = useAuth()
  const [email, setEmail] = useState(user?.email || "")
  const [message, setMessage] = useState("")
  const [messageSubmitted, setMessageSubmitted] = useState(false)

  const handleDMOnX = () => {
    window.open(
      "https://twitter.com/messages/compose?recipient_id=1611660808835964928",
      "_blank"
    )
  }

  const handleSendMessage = async (e) => {
    e.preventDefault()
    try {
      await telegram(`Support request:
Email: ${email}
Message: ${message}`)
    } catch (error) {
      console.error("Error sending support message to Telegram:", error)
    }

    setEmail("")
    setMessage("")
    setMessageSubmitted(true)
    setTimeout(() => {
      setMessageSubmitted(false)
    }, 3000)
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-neutral-900 min-h-screen">
      <h1 className="text-3xl font-bold mb-8 text-white">Support</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <SupportOption
          title="DM me on X"
          onClick={handleDMOnX}
          icon={FaTwitter}
        />
        <div className="bg-neutral-800 border border-neutral-700 shadow-md rounded-lg p-8">
          <h3 className="text-2xl font-semibold mb-6 text-white text-center">
            Leave a Message
          </h3>
          {!messageSubmitted ? (
            <form onSubmit={handleSendMessage}>
              {user ? (
                <input
                  type="email"
                  value={user.email}
                  readOnly
                  className="w-full p-2 mb-4 bg-neutral-700 text-white rounded"
                />
              ) : (
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  className="w-full p-2 mb-4 bg-neutral-700 text-white rounded"
                  required
                />
              )}
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Your message"
                className="w-full p-2 mb-4 bg-neutral-700 text-white rounded h-32"
                required
              />
              <button
                type="submit"
                className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
              >
                Send
              </button>
            </form>
          ) : (
            <div className="text-green-500 text-xl font-semibold text-center">
              Message submitted!
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Support
