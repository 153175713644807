import { useAuth } from "../../context/AuthContext"
import { useEffect, useState } from "react"
import { firestore } from "../../firebase"
import Recent from "./Recent"

export default function Admin() {
  const { user } = useAuth()
  const [userid, setUserId] = useState("")
  const [email, setEmail] = useState("")
  const [tokens, setTokens] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState({})
  const [imageData, setImageData] = useState([])
  const [info, setInfo] = useState("")
  const [mode, setMode] = useState("get-user")

  useEffect(() => {
    setEmail("")
  }, [userid])

  useEffect(() => {
    setUserId("")
  }, [email])

  const formatDate = (timestamp) => {
    if (!timestamp) return ""
    const date = new Date(timestamp.seconds * 1000) // Convert seconds to milliseconds
    return (
      date.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }) +
      " " +
      date.toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    )
  }

  const totalCredits = imageData.reduce((acc, image) => {
    const credits = parseInt(image.creditCost, 10) || 0
    return acc + credits
  }, 0)

  const getUser = async () => {
    setIsLoading(true)
    try {
      if (userid === "" && email === "") {
        setInfo("Please enter userid or email")
        setIsLoading(false)
        return
      }

      let userRef = null
      let doc = null

      if (userid) {
        userRef = firestore.collection("users").doc(userid)
        doc = await userRef.get()
      } else if (email) {
        userRef = firestore.collection("users").where("email", "==", email)
        const querySnapshot = await userRef.get()
        if (!querySnapshot.empty) {
          doc = querySnapshot.docs[0]
        }
      }

      if (doc && doc.exists) {
        const userData = doc.data()
        setUserData(userData)
        const imagesCollectionRef = firestore
          .collection("users")
          .doc(doc.id)
          .collection("images")
        const imagesSnapshot = await imagesCollectionRef.get()
        const images = imagesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        setImageData(images)
        setInfo("found user")
      } else {
        setInfo("No such document!")
      }
    } catch (error) {
      setInfo("error: " + error.message)
    }
    setIsLoading(false)
  }

  const giveTokens = async () => {
    try {
      setIsLoading(true)
      if (userid === "" && email === "") {
        setInfo("Please enter userid or email")
        setIsLoading(false)
        return
      }

      let userRef = null
      let doc = null

      if (userid) {
        userRef = firestore.collection("users").doc(userid)
        doc = await userRef.get()
      } else if (email) {
        const querySnapshot = await firestore
          .collection("users")
          .where("email", "==", email)
          .get()
        if (!querySnapshot.empty) {
          doc = querySnapshot.docs[0]
          userRef = doc.ref
        }
      }

      if (doc && doc.exists) {
        const newTokens = parseInt(tokens, 10) || 0
        await userRef.update({ balance: newTokens })
        setInfo("Tokens updated")
      } else {
        setInfo("No such document!")
      }
    } catch (error) {
      setInfo("error: " + error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {user?.email === "genaiconsult@gmail.com" && (
        <div className="w-full h-full flex flex-col items-center bg-bg-color text-white flex-grow">
          <div className="h-full relative w-full flex lg:flex-row flex-col">
            <div className="w-full relative p-6 lg:h-screen-16 py-6 lg:overflow-y-auto lg:max-w-xs lg:mr-1 lg:pr-4 lg:border-r border-neutral-700">
              <div className="flex justify-center py-1 bg-neutral-800 border border-neutral-700 rounded-lg">
                Admin Area
              </div>
              <div className="flex h-10 justify-center my-2 py-1 bg-neutral-800 border border-neutral-700 rounded-lg">
                <button
                  onClick={() => setMode("get-user")}
                  className={`w-1/2 ml-2 mr-1 transition-colors duration-300 rounded-lg ease-in-out ${
                    mode === "get-user"
                      ? "bg-blue-600 text-white"
                      : "text-neutral-300 hover:bg-neutral-700 hover:text-white"
                  }`}
                >
                  Get user
                </button>
                <button
                  onClick={() => setMode("set-tokens")}
                  className={`w-1/2 ml-1 mr-2 transition-colors duration-300 rounded-lg ease-in-out ${
                    mode === "set-tokens"
                      ? "bg-blue-600 text-white"
                      : "text-neutral-300 hover:bg-neutral-700 hover:text-white"
                  }`}
                >
                  Set tokens
                </button>
              </div>

              <p className="font-bold mt-6 mb-2">userid</p>
              <textarea
                id="userid"
                type="text"
                rows="1"
                className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
                value={userid}
                onChange={(e) => setUserId(e.target.value)}
              />

              <p className="font-bold mt-6 mb-2">email</p>
              <textarea
                id="email"
                type="text"
                rows="1"
                className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {mode === "set-tokens" && (
                <>
                  <p className="font-bold mt-6 mb-2">tokens amount</p>
                  <textarea
                    id="tokens"
                    type="text"
                    rows="1"
                    className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
                    value={tokens}
                    onChange={(e) => setTokens(e.target.value)}
                  />
                  <button
                    type="submit"
                    disabled={isLoading}
                    onClick={giveTokens}
                    className="mt-8 w-full h-12 rounded-lg font-bold text-white bg-gradient-to-r from-cyan-500 to-blue-500 via-purple-500 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate"
                  >
                    <div className="flex flex-row place-content-center">
                      {isLoading ? (
                        <div className="flex justify-center items-center">
                          <p className="pr-2">Loading</p>
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5  text-white animate-spin  fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                      ) : (
                        <span>Set tokens</span>
                      )}
                    </div>
                  </button>
                </>
              )}
              {mode === "get-user" && (
                <>
                  <button
                    type="submit"
                    disabled={isLoading}
                    onClick={getUser}
                    className="mt-8 w-full h-12 rounded-lg font-bold text-white bg-gradient-to-r from-cyan-500 to-blue-500 via-purple-500 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate"
                  >
                    <div className="flex flex-row place-content-center">
                      {isLoading ? (
                        <div className="flex justify-center items-center">
                          <p className="pr-2">Loading</p>
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5  text-white animate-spin  fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                      ) : (
                        <span> Get user</span>
                      )}
                    </div>
                  </button>

                  <div className="mt-4 text-left">
                    <div className="flex justify-center py-1 bg-neutral-800 border border-neutral-700 rounded-lg">
                      User Info: {info}
                    </div>
                    <div>User Details:</div>
                    {userData && (
                      <table className="w-full text-sm text-left text-gray-500">
                        <tbody>
                          {Object.entries(userData).map(([key, value]) => (
                            <tr key={key}>
                              <td className="py-2 font-medium text-white">
                                {key}
                              </td>
                              <td className="py-2 text-white">
                                {key === "createdAt"
                                  ? formatDate(value)
                                  : JSON.stringify(value)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    <div>Calculated user details:</div>
                    <table className="w-full text-sm text-left text-gray-500">
                      <tbody>
                        <tr>
                          <td className="py-2 font-medium text-white">
                            total images
                          </td>
                          <td className="py-2 text-white">
                            {imageData.length}
                          </td>
                        </tr>
                        <tr>
                          <td className="py-2 font-medium text-white">
                            total credits spend
                          </td>
                          <td className="py-2 text-white">{totalCredits}</td>
                        </tr>
                        <tr>
                          <td className="py-2 font-medium text-white">
                            total gpu time
                          </td>
                          <td className="py-2 text-white">
                            {imageData
                              .reduce(
                                (acc, image) =>
                                  acc + (parseFloat(image.predictTime) || 0),
                                0
                              )
                              .toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
            <div className="h-full w-full px-6 py-6 lg:h-screen-16 lg:overflow-y-auto lg:overflow-x-hidden">
              {/* <p className="text-2xl font-bold mt-6 mb-4">Results</p> */}
              <Recent customImages={imageData} showMetaAdmin={true} />
            </div>
          </div>
        </div>
      )}{" "}
    </>
  )
}
