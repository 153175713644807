import React from "react"
import "./assets/App.css"
import Dashboard from "./pages/Dashboard/Dashboard"
import Header from "./pages/Landingpage/Header"
import Hero from "./pages/Landingpage/Hero"
import Register from "./pages/Sign/Register"
import Login from "./pages/Sign/Login"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import ForgotPassword from "./pages/Sign/ForgotPassword"
import { AuthProvider } from "./context/AuthContext"
import PrivateRoute from "./components/PrivateRoute"
import Pricing from "./components/Pricing"
import Examples from "./pages/Landingpage/Examples"
import CalltoAction from "./pages/Landingpage/CalltoAction"
import Popup from "./pages/Sign/Popup"
import FAQs from "./components/Faq"
import Docs from "./pages/API/Docs"
import ComfyUI from "./pages/API/ComfyUI"
import Admin from "./pages/Dashboard/Admin"
import AdminDashboard from "./pages/Dashboard/AdminDashboard"
import Success from "./pages/Dashboard/Success"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Testimonials from "./pages/Landingpage/Testimonials"
import Features from "./pages/Landingpage/Features"
import Footer from "./pages/Landingpage/Footer"
import { useParams, Redirect } from "react-router-dom"
import Billing from "./pages/Billing"
import Support from "./pages/Support"

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </AuthProvider>
    </HelmetProvider>
  )
}

function AppContent() {
  return (
    <div className="flex flex-col min-h-screen bg-bg-color">
      <Switch>
        <Route exact path="/login">
          <Header />
          <Login />
        </Route>
        <Route exact path="/register">
          <Header />
          <Register />
        </Route>
        <Route exact path="/forgot-password">
          <Header />
          <ForgotPassword />
        </Route>
        <Route exact path="/">
          <Helmet>
            <title>Clarity AI - #1 AI Image Upscaler & Enhancer</title>
            <meta
              name="description"
              content="Upscale and enhance your images with the power of AI. Clarity AI is the #1 AI Image Upscaler & Enhancer with API support. Enlarge images and get HD quality."
            />
            <link rel="canonical" href={`https://clarityai.co`} />
          </Helmet>
          <Header />
          <Popup />
          <Hero />
          <Features />
          <Examples />
          <FAQs />
          <Testimonials />
          <CalltoAction />
          <Footer />
        </Route>
        <Route exact path="/dashboard">
          <Header />
          <Helmet>
            <title>Clarity AI Dashboard - Upscale & Enhance your Images</title>
            <meta
              name="description"
              content="Access your Clarity AI dashboard to manage your account and settings. Utilize the leading AI technology for image upscaling and enhancement, all from one central location."
            />
            <link rel="canonical" href="https://clarityai.co/dashboard" />
          </Helmet>
          <Dashboard />
        </Route>
        <Route exact path="/api">
          <Helmet>
            <title>API Image Upscaler & Enhancer - Clarity AI</title>
            <meta
              name="description"
              content="API to upscale & enhance images. Business API to get high resolution images. Benefit from permanent output files, simple parameters, optimized presets, and fast prediction times."
            />
            <link rel="canonical" href="https://clarityai.co/api" />
          </Helmet>
          <Header />
          <Docs />
        </Route>
        <Route exact path="/comfyui">
          <Helmet>
            <title>ComfyUI Upscaler & Enhancer - Clarity AI Plugin</title>
            <meta
              name="description"
              content="The ComfyUI for Clarity AI. Enhance and upscale your images with ease using our AI-driven solutions, optimized for high resolution and perfect results."
            />
            <link rel="canonical" href="https://clarityai.co/comfyui" />
          </Helmet>
          <Header />
          <ComfyUI />
        </Route>
        <Route exact path="/pricing">
          <Helmet>
            <title>Pricing - Clarity AI Image Upscaler & Enhancer</title>
            <meta
              name="description"
              content="Explore Clarity AI's flexible pricing options for our advanced image upscaler and enhancer. Choose from subscription-based plans or one-off purchases to suit your needs."
            />
            <link rel="canonical" href="https://clarityai.co/pricing" />
          </Helmet>
          <Header />
          <Pricing />
        </Route>

        <Route exact path="/success">
          <Header />
          <Success />
        </Route>
        <Route exact path="/billing">
          <Header />
          <Helmet>
            <title>Billing - Clarity AI</title>
            <meta
              name="description"
              content="Manage your billing settings for Clarity AI. Change your plan, cancel your subscription, or get support."
            />
            <link rel="canonical" href="https://clarityai.co/billing" />
          </Helmet>
          <Billing />
        </Route>
        <Route exact path="/support">
          <Helmet>
            <title>Support - Clarity AI</title>
            <meta
              name="description"
              content="Get support for Clarity AI. Contact me for any issues or questions."
            />
            <link rel="canonical" href="https://clarityai.co/support" />
          </Helmet>
          <Header />
          <Support />
        </Route>
        <PrivateRoute exact path="/admin">
          <Header />
          <Admin />
        </PrivateRoute>
        <PrivateRoute exact path="/admin-dashboard">
          <Header />
          <AdminDashboard />
        </PrivateRoute>
        <Route path="/:langOrKeyword/:keyword?">
          <DynamicLandingPage />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  )
}

const ALLOWED_KEYWORDS = ["anime-image-upscaling"]

const KEYWORD_DATA = {
  "anime-image-upscaling": {
    en: {
      title: "Anime Image Upscaling - Clarity AI - #1 AI Anime Upscaler",
      description:
        "Upscale and enhance your anime images with Clarity AI. Our advanced AI technology preserves the unique style of anime while increasing resolution and detail.",
    },
    de: {
      title: "Anime-Bildvergrößerung - Clarity AI - #1 KI-Anime-Upscaler",
      description:
        "Vergrößern und verbessern Sie Ihre Anime-Bilder mit Clarity AI. Unsere fortschrittliche KI-Technologie bewahrt den einzigartigen Stil von Anime und erhöht gleichzeitig Auflösung und Details.",
    },
  },
}

const LANGUAGE_DATA = {
  de: {
    title: "Clarity AI - #1 KI-Bildvergrößerer & -Verbesserer",
    description:
      "Vergrößern und verbessern Sie Ihre Bilder mit der Kraft der KI. Clarity AI ist der führende KI-Bildvergrößerer & -Verbesserer mit API-Unterstützung.",
  },
}

const validLanguages = ["de", "ru", "ja"]

function DynamicLandingPage() {
  const { langOrKeyword, keyword } = useParams()

  let language = "en"
  let actualKeyword = keyword

  if (validLanguages.includes(langOrKeyword)) {
    language = langOrKeyword
    actualKeyword = keyword
  } else {
    actualKeyword = langOrKeyword
  }

  if (actualKeyword && !ALLOWED_KEYWORDS.includes(actualKeyword)) {
    return <Redirect to="/" />
  }

  let pageData
  if (actualKeyword) {
    const keywordData = KEYWORD_DATA[actualKeyword]
    pageData = keywordData[language] ||
      keywordData.en || {
        title: "Clarity AI - #1 AI Image Upscaler & Enhancer",
        description:
          "Upscale and enhance your images with the power of AI. Clarity AI is the #1 AI Image Upscaler & Enhancer with API support. Enlarge images and get HD quality.",
      }
  } else if (language !== "en") {
    pageData = LANGUAGE_DATA[language] || {
      title: "Clarity AI - #1 AI Image Upscaler & Enhancer",
      description:
        "Upscale and enhance your images with the power of AI. Clarity AI is the #1 AI Image Upscaler & Enhancer with API support. Enlarge images and get HD quality.",
    }
  } else {
    pageData = {
      title: "Clarity AI - #1 AI Image Upscaler & Enhancer",
      description:
        "Upscale and enhance your images with the power of AI. Clarity AI is the #1 AI Image Upscaler & Enhancer with API support. Enlarge images and get HD quality.",
    }
  }

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <link
          rel="canonical"
          href={`https://clarityai.co${
            language !== "en" ? `/${language}` : ""
          }${actualKeyword ? `/${actualKeyword}` : ""}`.replace(/\/+$/, "")}
        />
      </Helmet>
      <Popup />
      <Header language={language} />
      <Hero keyword={actualKeyword} language={language} />
      <Features keyword={actualKeyword} language={language} />
      <Examples keyword={actualKeyword} language={language} />
      <FAQs keyword={actualKeyword} language={language} />
      <Testimonials keyword={actualKeyword} language={language} />
      <CalltoAction keyword={actualKeyword} language={language} />
      <Footer language={language} />
    </>
  )
}

export default App
