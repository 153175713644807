import { IconDownload, IconDelete } from "./Icons"
import { firestore } from "../firebase"

export function Image({ image }) {
  const handleDownload = async () => {
    const imageUrl = image.outputImage

    const link = document.createElement("a")
    link.style.display = "none"
    document.body.appendChild(link)

    try {
      const response = await fetch(imageUrl, { mode: "cors" })
      if (!response.ok) {
        throw new Error(`HTTP status: ${response.status}`)
      }
      const blob = await response.blob()
      if (blob && blob.size > 0) {
        const url = window.URL.createObjectURL(blob)
        link.href = url
        const originalFileName = "clarityai-" + imageUrl.split("/").pop()
        link.download = originalFileName
        link.click()
        window.URL.revokeObjectURL(url)
      } else {
        throw new Error("Blob is empty or undefined.")
      }
    } catch (error) {
      console.error("Error fetching or downloading the image:", error)
    } finally {
      document.body.removeChild(link)
    }
  }

  const handleDelete = async () => {
    await firestore
      .collection("users")
      .doc(image.userId)
      .collection("images")
      .doc(image.id)
      .update({ deleted: true })
  }

  return (
    <div className="flex w-full relative">
      <img
        alt="display"
        src={image?.outputImage}
        className="w-full rounded-lg"
      />
      <div className="absolute bottom-4 left-4 flex">
        <button
          onClick={handleDownload}
          className="p-2 bg-black/70 flex flex-row items-center rounded-full mr-1.5"
        >
          <IconDownload size={18} />
        </button>
        <button
          className="p-[6px] bg-black/70 flex flex-row items-center rounded-full ml-1.5"
          onClick={handleDelete}
        >
          <IconDelete size={22} />
        </button>
      </div>
    </div>
  )
}
