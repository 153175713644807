import React, { useEffect, useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { firestore, serverTimestamp } from "../../firebase"
import { telegram } from "../../utils/telegram"
import { uploadToR2 } from "../../components/uploadToR2"
import { useHistory } from "react-router-dom"

export default function StyleVideo({
  setCreateImageFunction,
  creditCost,
  setCreditCost,
}) {
  const history = useHistory()
  const { user } = useAuth()

  const [error, setError] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    width: 0,
    height: 0,
    fileUrl: "",
  })

  const [style, setStyle] = useState("anime")
  const [prompt, setPrompt] = useState("")
  const [videoDuration, setVideoDuration] = useState(0)

  useEffect(() => {
    if (selectedFile && selectedFile.file) {
      const file = selectedFile.file
      const videoElement = document.createElement("video")

      const url = URL.createObjectURL(file)
      videoElement.src = url

      videoElement.addEventListener(
        "loadedmetadata",
        () => {
          setVideoDuration(videoElement.duration)
          URL.revokeObjectURL(url)
        },
        { once: true }
      )
    }
  }, [selectedFile])

  useEffect(() => {
    if (videoDuration) {
      const adjustedDuration = Math.min(videoDuration, 30)
      const baseCost = adjustedDuration * 40
      const roundedToFive = Math.ceil(baseCost / 5) * 5
      const tempCreditCost = Math.max(roundedToFive, 5)

      setCreditCost(tempCreditCost)
    }
  }, [videoDuration])

  const handleFileChange = (event) => {
    setError(null)
    const file = event.target.files[0]
    const fileUrl = URL.createObjectURL(file)
    const img = new Image()

    img.onload = () => {
      setSelectedFile({
        file: file,
        width: img.width,
        height: img.height,
        fileUrl: fileUrl,
      })
    }
    img.src = fileUrl
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()

    const files = e.dataTransfer.files

    if (files.length > 0) {
      const file = files[0]
      const fileUrl = URL.createObjectURL(file)
      const img = new Image()

      img.onload = () => {
        setSelectedFile({
          file: file,
          width: img.width,
          height: img.height,
          fileUrl: fileUrl,
        })
      }

      img.src = fileUrl
    }
  }

  const createImageDocument = async () => {
    try {
      setError(null)
      if (!user) {
        telegram(
          "Create.js, createImageDocument, user not found!, user: " + user
        )
        setError("Register to get tokens.")
        return
      }

      if (user.balance <= 0 || user.balance < creditCost) {
        setError("Not enough tokens!")
        history.push("/pricing")
        return
      }

      if (!selectedFile.file) {
        setError("No video selected!")
        return
      }
      const downloadURL = await uploadToR2(selectedFile.file, false) //false bc no convert to jpg
      const credits = user.balance - creditCost
      try {
        await firestore.collection("users").doc(user.id).update({
          balance: credits,
        })
      } catch (error) {
        telegram("Create, createImageDocument, error: " + error.message)
        setError("Error while updating tokens!")
        return null
      }
      const imageData = {
        userId: user.id,
        createdAt: serverTimestamp(),
        status: "created",
        mode: "video-transfer",
        inputImage: downloadURL,
        style: style,
        prompt: prompt,
        creditCost: creditCost,
        width: selectedFile.width,
        height: selectedFile.height,
      }

      try {
        const imagesCollectionRef = firestore
          .collection("users")
          .doc(user.id)
          .collection("images")

        const docRef = await imagesCollectionRef.add(imageData)
        return docRef.id
      } catch (error) {
        telegram("Create.js, createImageDocument1, error: " + error.message)
        return null
      }
    } catch (error) {
      telegram("Create.js, createImageDocument2, error: " + error.message)
      return null
    }
  }

  useEffect(() => {
    setCreateImageFunction(
      () => () =>
        createImageDocument(
          user,
          selectedFile,
          creditCost,
          style,
          prompt,
          setError
        )
    )
  }, [user, selectedFile, creditCost, style, prompt, setError])

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectedFile({ file: null })
  }

  return (
    <div className="w-full pl-3 pr-1 py-2 pb-3 relative md:h-[calc(100vh-194px)] md:overflow-y-auto md:max-w-xs md:pr-3 border-neutral-700">
      <div
        className="file-uploader"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      ></div>
      <p className="font-bold mt-2 mb-2">Input video</p>
      <div
        className={
          selectedFile.file
            ? "w-full max-h-64 relative"
            : "flex flex-col items-center justify-center w-full h-32 border border-neutral-700 border-dashed rounded-lg cursor-pointer bg-neutral-800 hover:bg-neutral-700"
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <label htmlFor="dropzone-file" className="w-full h-full">
          {selectedFile.file ? (
            <div className="relative">
              <img
                className="max-h-64 rounded-lg cursor-pointer bg-neutral-800 hover:bg-gray-100"
                src={selectedFile.fileUrl}
                alt="preview"
              />
              <div
                className="absolute text-xs px-2 left-1 top-1 w-14 bg-neutral-800/70 p-1 rounded-lg font-bold cursor-pointer hover:bg-opacity-80 hover:text-black"
                onClick={handleDeleteClick}
              >
                Delete
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <>
                <svg
                  className="w-8 h-8 mb-4 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>

                <p className="mb-2 px-2 text-sm text-white text-center">
                  Click to upload or drop your video
                </p>
              </>
            </div>
          )}
        </label>
        <input
          type="file"
          id="dropzone-file"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      <p className="font-bold mt-6 mb-2">Prompt (optional)</p>
      <textarea
        id="prompt"
        type="text"
        rows="3"
        className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />

      <div>
        <div>
          <div className="flex items-center">
            <p className="font-bold my-2 mr-2 mt-2">Style</p>
          </div>
          <select
            className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
            value={style}
            onChange={(event) => setStyle(event.target.value)}
          >
            <option value="anime">Anime</option>
          </select>
        </div>
      </div>

      {selectedFile.file && (
        <div className="flex flex-col items-center justify-center my-4 py-1 bg-neutral-800 border border-neutral-700 rounded-lg text-sm text-neutral-400">
          <div className="text-center">
            <p>
              {videoDuration > 30
                ? "Video is cut to 30 seconds."
                : `Video duration: ${Math.round(videoDuration)} seconds`}
            </p>

            {creditCost ? (
              <p>Will cost {creditCost} tokens</p>
            ) : (
              <p className="text-red-800">
                Exceeds maximum of 13,000 x 13,000 pixel
              </p>
            )}
          </div>
        </div>
      )}

      {error && (
        <div
          className="flex items-center p-4 mb-4 text-sm border rounded-lg bg-gray-800 text-red-400 border-red-800"
          role="alert"
        >
          <div> {error}</div>
        </div>
      )}
    </div>
  )
}
