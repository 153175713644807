import React, { useState } from "react"
import Recent from "./Recent"
import Popup from "../Sign/Popup"
import Options from "./Options"
import Sidebar from "./Sidebar"

export default function Dashboard() {
  const [mode, setMode] = useState("upscale")
  const [showPopup, setShowPopup] = useState(false)

  return (
    <div className="w-full flex flex-col items-center bg-bg-color text-white flex-grow">
      <div className="flex w-full md:flex-row flex-col">
        <Sidebar mode={mode} setMode={setMode} />

        <Options mode={mode} setShowPopup={setShowPopup} />
        <div className="w-full px-3 py-2 md:h-screen-16 md:overflow-y-auto md:overflow-x-hidden border-t border-neutral-700 md:border-0 mt-4 pt-4 md:mt-0 md:pt-2">
          <Recent />
        </div>
      </div>
      <Popup show={showPopup} onClose={() => setShowPopup(false)} />
    </div>
  )
}
