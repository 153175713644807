import { useRef } from "react"
//import { ImgComparisonSlider } from "@img-comparison-slider/react"
import useRefDimensions from "../hooks/useRefDimensions.js"
import { IconDownload, IconConfig, IconDelete } from "./Icons"
import ZoomableImgComparisonSlider from "./ZoomableImgComparisonSlider"

import { firestore } from "../firebase"

export function ComparisonSlider({
  image,
  showControls = false,
  showMeta = false,
  setOptions,
  showMetaAdmin,
  scrollZoomEnabled = false,
}) {
  // const { prompt = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren," } = image

  // This is needed to get the width of the slider
  const sliderRef = useRef()
  const { width } = useRefDimensions(sliderRef, {
    debug: false,
    debugId: image?.outputImage,
  })
  // const closeModal = () => {
  //   setIsModalOpen(false)
  // }

  // const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDownload = async () => {
    const imageUrl = image.outputImage // Stelle sicher, dass diese Variable definiert und gültig ist

    // Erstelle ein <a> Element zur Verwendung für den Download
    const link = document.createElement("a")
    link.style.display = "none"
    document.body.appendChild(link)

    fetch(imageUrl, { mode: "cors" })
      .then((response) => {
        if (!response.ok) {
          console.error(`HTTP status: ${response.status}`)
          return
        }
        return response.blob()
      })
      .then((blob) => {
        if (blob && blob.size > 0) {
          // Erstelle eine URL für den Blob und starte den Download
          const url = window.URL.createObjectURL(blob)
          link.href = url

          // Generiere einen Dateinamen basierend auf der URL
          const originalFileName = "clarityai-" + imageUrl.split("/").pop()
          link.download = originalFileName

          // Führe den Download aus
          link.click()

          // Bereinige die URL und das Link-Element
          window.URL.revokeObjectURL(url)
          document.body.removeChild(link)
        } else {
          console.error("Blob is empty or undefined.")
        }
      })
      .catch((error) => {
        console.error("Error fetching or downloading the image:", error)
      })
  }

  const handleDelete = async () => {
    await firestore
      .collection("users")
      .doc(image.userId)
      .collection("images")
      .doc(image.id)
      .update({ deleted: true })
  }
  const getImageTags = (image) => {
    if (image.mode === "style-transfer") {
      return [
        {
          label: "Style strength",
          value: image?.styleStrength ?? 0,
        },
        {
          label: "Structure strength",
          value: image?.structureStrength ?? 0,
        },
        {
          label: "version",
          value: image?.styleTransferVersion ?? 0,
        },
      ]
    } else {
      return [
        {
          label: "Creativity",
          value: image?.creativity ?? 0,
        },
        {
          label: "Resemblance",
          value: image?.resemblance ?? 0,
        },
        {
          label: "Dynamic",
          value: image?.dynamic ?? 0,
        },
        {
          label: "Style",
          value: image?.style
            ? image.style.charAt(0).toUpperCase() + image.style.slice(1)
            : 0,
        },
        {
          label: "Fractality",
          value: image.segmentation !== undefined ? image.segmentation : 0,
        },
        {
          label: "x",
          value: image.multiplier !== undefined ? image.multiplier : 0,
        },
      ]
    }
  }

  const getAdminImageTags = (image) => {
    return [
      {
        label: "time",
        value: image.predictTime?.toFixed(2) + "s",
      },
      {
        label: "credits",
        value: image.creditCost,
      },
    ]
  }

  return (
    <>
      <div className="image-slider relative">
        {/* Tags on top of image slider */}

        <div
          className="absolute z-10 flex w-full bottom-0 justify-between pointer-events-none p-4"
          style={{ width }}
        >
          {/* Bottom left contets */}
          {showControls && (
            <div className="flex flex-row items-end pointer-events-auto">
              {/* <button
                className="p-2 bg-black/70 flex flex-row items-center rounded-full mr-1.5"
                onClick={() => setIsModalOpen(true)}
              >
                <IconOpen size={16} />

              </button> */}

              {/* <a
                href={image.outputImage}
                download
                className="p-2 bg-black/70 flex flex-row items-center rounded-full mr-1.5"
              >
                <IconDownload size={18} />
              </a> */}

              <button
                onClick={handleDownload}
                className="p-2 bg-black/70 flex flex-row items-center rounded-full mr-1.5"
              >
                <IconDownload size={18} />
              </button>
              {/* 
              <button
                className="p-2 bg-black/70 flex flex-row items-center rounded-full"
                onClick={() =>
                  setOptions(
                    image.creativity,
                    image.multiplier,
                    image.prompt,
                    image.dynamic,
                    image.resemblance
                  )
                }
              >
                <IconConfig size={19} />
          
              </button> */}

              <button
                className="p-[6px] bg-black/70 flex flex-row items-center rounded-full ml-1.5"
                onClick={handleDelete}
              >
                <IconDelete size={22} />
                {/* <p className="ml-2 text-xs">Download</p> */}
              </button>
            </div>
          )}

          {/* Bottom right contents */}
          <div className="md:visible invisible flex flex-col items-end justify-end ml-10 pointer-events-auto">
            {/* AdminTags */}
            <div className="flex">
              {showMetaAdmin &&
                width > 729 &&
                getAdminImageTags(image)?.map((tag, index) => (
                  <div
                    key={index}
                    className="flex items-center px-2 py-1 bg-black/70 rounded-xl mr-2 last:mr-0"
                  >
                    <span className="text-xs text-neutral-400 mr-1.5">
                      {tag.label}
                    </span>
                    <span className="text-xs font-bold text-white">
                      {tag.value}
                    </span>
                  </div>
                ))}
            </div>

            {/* Prompt */}
            {showMeta && image.prompt && (
              <div className="flex max-w-md items-start px-2 py-1 bg-black/70 rounded-xl mb-1.5">
                <span className="text-xs text-white/50 mr-1.5">Prompt</span>
                <span className="text-xs font-bold text-white italic text-left">
                  {image.prompt.length > 100
                    ? `${image.prompt.slice(0, 100)}...`
                    : image.prompt}
                </span>
              </div>
            )}

            {/* Tags */}
            <div className="flex">
              {showMeta &&
                width > 729 &&
                getImageTags(image)?.map((tag, index) => (
                  <div
                    key={index}
                    className="flex items-center px-2 py-1 bg-black/70 rounded-xl mr-2 last:mr-0"
                  >
                    <span className="text-xs text-neutral-400 mr-1.5">
                      {tag.label}
                    </span>
                    <span className="text-xs font-bold text-white">
                      {tag.value}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <ZoomableImgComparisonSlider
          image={image}
          scrollZoomEnabled={scrollZoomEnabled}
          ref={sliderRef}
        />

        {/* <ImgComparisonSlider ref={sliderRef}>
          <figure slot="first" className="relative">
            <img
              alt="before"
              src={image?.inputImage}
              className="w-full rounded-lg"
            />

            <div className="absolute text-xs text-black bg-white/70 rounded-xl font-bold px-3 py-1 top-4 left-4">
              Before
            </div>
          </figure>
          <figure slot="second" className="relative">
            <img
              alt="after"
              src={image?.outputImage}
              className="w-full rounded-lg"
            />
            <div className="absolute text-xs text-white rounded-xl font-bold px-3 py-1 top-4 right-4 bg-gradient-to-r via-blue-500  to-emerald-600 from-sky-400 background-animate">
              After
            </div>
          </figure>
        </ImgComparisonSlider> */}
      </div>

      {/* {isModalOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex items-center justify-center"
          onClick={closeModal} // use closeModal function here
        >
          <img
            src={image?.outputImage}
            alt="results"
            className="max-h-[85%] max-w-[85%]"
          />
        </div>
      )} */}
    </>
  )
}
