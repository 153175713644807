import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
} from "react"
import { ImgComparisonSlider } from "@img-comparison-slider/react"
import { debounce } from "lodash"

const ZoomableImgComparisonSlider = forwardRef(
  ({ image, scrollZoomEnabled }, ref) => {
    const [zoom, setZoom] = useState(1)
    const zoomRef = useRef(zoom)
    const [isZooming, setIsZooming] = useState(false)
    const [position, setPosition] = useState({ x: 0, y: 0 })
    const internalSliderRef = useRef(null)
    const requestRef = useRef()
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === "z" && scrollZoomEnabled) {
          setIsZooming(true)
          document.body.style.overflow = "hidden" // Disable page scroll
        }
      }

      const handleKeyUp = (event) => {
        if (event.key === "z" && scrollZoomEnabled) {
          setIsZooming(false)
          document.body.style.overflow = "" // Enable page scroll
        }
      }

      window.addEventListener("keydown", handleKeyDown)
      window.addEventListener("keyup", handleKeyUp)

      return () => {
        window.removeEventListener("keydown", handleKeyDown)
        window.removeEventListener("keyup", handleKeyUp)
        document.body.style.overflow = "" // Ensure scroll is enabled when component unmounts
      }
    }, [scrollZoomEnabled])

    useEffect(() => {
      zoomRef.current = zoom
    }, [zoom])

    const handleWheel = useCallback(
      (event) => {
        if (isZooming && isActive && scrollZoomEnabled) {
          event.preventDefault() // Prevent page zoom
          const newZoom = Math.min(
            Math.max(zoomRef.current + event.deltaY * -0.01, 1),
            20
          )
          if (newZoom !== zoomRef.current) {
            zoomRef.current = newZoom
            requestRef.current = requestAnimationFrame(() => {
              setZoom(zoomRef.current)
            })
          }
        }
      },
      [isZooming, isActive, scrollZoomEnabled]
    )

    const debouncedHandleMouseMove = useCallback(
      debounce((movementX, movementY) => {
        setPosition((prevPosition) => ({
          x: prevPosition.x + movementX,
          y: prevPosition.y + movementY,
        }))
      }, 10),
      []
    )

    const handleMouseMove = (event) => {
      if (isZooming && isActive && scrollZoomEnabled) {
        debouncedHandleMouseMove(event.movementX, event.movementY)
      }
    }

    useEffect(() => {
      document.addEventListener("wheel", handleWheel, { passive: false })

      return () => {
        document.removeEventListener("wheel", handleWheel)
        if (requestRef.current) {
          cancelAnimationFrame(requestRef.current)
        }
      }
    }, [handleWheel])

    return (
      <div
        ref={ref || internalSliderRef}
        onMouseMove={handleMouseMove}
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
        style={{ overflow: "hidden", cursor: isZooming ? "grab" : "default" }}
      >
        <ImgComparisonSlider ref={internalSliderRef}>
          <figure slot="first" className="relative">
            <img
              alt={image.altInput || "Low resolution image"}
              src={image?.inputImage}
              className="w-full rounded-lg"
              style={{
                transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                transition: "transform 0.1s",
              }}
              // loading="lazy"
            />
            <div className="absolute text-xs text-black bg-white/70 rounded-xl font-bold px-3 py-1 top-4 left-4">
              Before
            </div>
          </figure>
          <figure slot="second" className="relative">
            <img
              alt={
                image.altOutput ||
                "High resolution image after upscaling and enhancing with AI"
              }
              src={image?.outputImage}
              className="w-full rounded-lg"
              style={{
                transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                transition: "transform 0.1s",
              }}
              // loading="lazy"
            />
            <div className="absolute text-xs text-white rounded-xl font-bold px-3 py-1 top-4 right-4 bg-gradient-to-r via-blue-500  to-emerald-600 from-sky-400 background-animate">
              After
            </div>
          </figure>
        </ImgComparisonSlider>
      </div>
    )
  }
)

export default ZoomableImgComparisonSlider
