import { useEffect, useState, useRef, useCallback } from "react"
import { firestore } from "../../firebase"

export default function AdminDashboard() {
  const [totalUsers, setTotalUsers] = useState(0)
  const [newUsersLast30Days, setNewUsersLast30Days] = useState([])
  const [lastFiftyUsers, setLastFiftyUsers] = useState([])
  const [groupStats, setGroupStats] = useState({ A: {}, B: {}, C: {} })
  const [recentCancellations, setRecentCancellations] = useState([])
  const canvasRef = useRef(null)
  const hoveredBar = useRef(null)

  useEffect(() => {
    fetchUserData()
  }, [])

  useEffect(() => {
    if (newUsersLast30Days.length > 0) {
      drawChart()
    }
  }, [newUsersLast30Days])

  const fetchUserData = async () => {
    try {
      const usersRef = firestore.collection("users")
      const startDate = new Date("2024-09-16")

      // Update total users count
      const totalUsersSnapshot = await usersRef
        .where("createdAt", ">=", startDate)
        .get()
      setTotalUsers(totalUsersSnapshot.size)

      // Update new users in last 30 days
      const thirtyDaysAgo = new Date()
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
      const newUsersStartDate =
        thirtyDaysAgo > startDate ? thirtyDaysAgo : startDate

      const newUsersQuery = await usersRef
        .where("createdAt", ">=", newUsersStartDate)
        .orderBy("createdAt")
        .get()

      const usersByDay = {}
      newUsersQuery.forEach((doc) => {
        const createdAt = doc.data().createdAt.toDate()
        const dateString = createdAt.toISOString().split("T")[0]
        usersByDay[dateString] = (usersByDay[dateString] || 0) + 1
      })

      const last30Days = [...Array(30)]
        .map((_, i) => {
          const d = new Date()
          d.setDate(d.getDate() - i)
          return d.toISOString().split("T")[0]
        })
        .reverse()

      const newUsersData = last30Days.map((date) => ({
        date,
        count: usersByDay[date] || 0,
      }))

      setNewUsersLast30Days(newUsersData)

      // Update last 50 users
      const lastFiftyUsersQuery = await usersRef
        .where("createdAt", ">=", startDate)
        .orderBy("createdAt", "desc")
        .limit(50)
        .get()

      const lastFiftyUsersData = lastFiftyUsersQuery.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      setLastFiftyUsers(lastFiftyUsersData)

      // Update group stats
      const groupASnapshot = await usersRef
        .where("group", "==", "A")
        .where("createdAt", ">=", startDate)
        .get()
      const groupBSnapshot = await usersRef
        .where("group", "==", "B")
        .where("createdAt", ">=", startDate)
        .get()
      const groupCSnapshot = await usersRef
        .where("group", "==", "C")
        .where("createdAt", ">=", startDate)
        .get()

      const groupAStats = await calculateGroupStats(groupASnapshot)
      const groupBStats = await calculateGroupStats(groupBSnapshot)
      const groupCStats = await calculateGroupStats(groupCSnapshot)

      setGroupStats({
        A: { ...groupAStats, credits: 0 },
        B: { ...groupBStats, credits: groupBStats.count * 20 },
        C: { ...groupCStats, credits: groupCStats.count * 20 },
      })

      console.log("Group stats:", {
        A: { ...groupAStats, credits: 0 },
        B: { ...groupBStats, credits: groupBStats.count * 20 },
        C: { ...groupCStats, credits: groupCStats.count * 20 },
      })

      // Update recent cancellations
      const cancellationsSnapshot = await usersRef
        .where("epoch_cancellation_requested", "!=", null)
        .where("createdAt", ">=", startDate)
        .orderBy("createdAt")
        .orderBy("epoch_cancellation_requested", "desc")
        .limit(500)
        .get()

      const cancellationsData = cancellationsSnapshot.docs
        .map((doc) => {
          const data = doc.data()
          return {
            id: doc.id,
            cancellationDate: new Date(
              data.epoch_cancellation_requested * 1000
            ).toLocaleDateString(),
            email: data.email || "",
            cancelReason: data.cancel_reason || "",
            cancelReasonType: data.cancel_reason_type || "",
            cancelFeedback: data.cancel_feedback || "",
          }
        })
        .filter((cancellation) => cancellation.cancelReasonType !== "")
        .slice(0, 100)

      setRecentCancellations(cancellationsData)

      console.log("Recent cancellations:", cancellationsData)
    } catch (error) {
      console.error("Error fetching user data:", error)
    }
  }

  const calculateGroupStats = async (snapshot) => {
    let count = snapshot.size
    let totalPayments = 0
    let totalCreditCosts = 0
    let paidUsers = 0
    let subscribedUsers = 0 // New variable to track subscribed users

    const promises = snapshot.docs.map(async (doc) => {
      const userData = doc.data()
      const paymentsSnapshot = await doc.ref.collection("payments").get()
      const payments = paymentsSnapshot.docs.reduce(
        (sum, paymentDoc) => sum + (paymentDoc.data().amount || 0),
        0
      )

      if (payments > 0) {
        paidUsers++
        if (userData.plan !== "free") {
          subscribedUsers++ // Increment if user has a non-free plan
        }
        console.log(
          `Paid user email: ${userData.email}, Group: ${userData.group}, Plan: ${userData.plan}`
        )
      }

      let creditCosts = 0
      if (userData.plan === "free") {
        const imagesSnapshot = await doc.ref.collection("images").get()
        creditCosts = imagesSnapshot.docs.reduce(
          (sum, imageDoc) => sum + (imageDoc.data().creditCost || 0),
          0
        )
      }

      return { payments, creditCosts }
    })

    const results = await Promise.all(promises)
    totalPayments = results.reduce((sum, result) => sum + result.payments, 0)
    totalCreditCosts = results.reduce(
      (sum, result) => sum + result.creditCosts,
      0
    )

    return {
      count,
      totalPayments,
      totalCreditCosts,
      paidUsers,
      subscribedUsers,
    } // Include subscribedUsers in the return object
  }

  const drawChart = useCallback(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    const width = canvas.width
    const height = canvas.height
    const padding = 60 // Erhöht für mehr Platz für Beschriftungen

    // Clear canvas
    ctx.clearRect(0, 0, width, height)

    // Draw axes
    ctx.beginPath()
    ctx.moveTo(padding, padding)
    ctx.lineTo(padding, height - padding)
    ctx.lineTo(width - padding, height - padding)
    ctx.stroke()

    // Calculate scales
    const maxCount = Math.max(...newUsersLast30Days.map((d) => d.count))
    const roundedMaxCount = Math.ceil(maxCount / 10) * 10 // Rundet auf nächste 10er
    const xScale = (width - 2 * padding) / newUsersLast30Days.length
    const yScale = (height - 2 * padding) / roundedMaxCount

    // Draw bars
    ctx.fillStyle = "rgb(75, 192, 192)"
    const barPositions = newUsersLast30Days.map((data, index) => {
      const x = padding + index * xScale
      const y = height - padding - data.count * yScale
      const barWidth = xScale * 0.8
      ctx.fillRect(x, y, barWidth, height - padding - y)
      return { x, y, width: barWidth, height: height - padding - y, data }
    })

    // Draw X-axis labels
    ctx.fillStyle = "white"
    ctx.font = "12px Arial"
    ctx.textAlign = "center"
    for (let i = 0; i < newUsersLast30Days.length; i += 5) {
      // Label every 5 days
      const x = padding + i * xScale + xScale / 2
      const date = new Date(newUsersLast30Days[i].date)
      const label = `${date.getDate()}.${date.getMonth() + 1}.`
      ctx.fillText(label, x, height - padding + 20)
    }

    // Draw Y-axis labels
    ctx.textAlign = "right"
    for (let i = 0; i <= roundedMaxCount; i += 10) {
      const y = height - padding - i * yScale
      ctx.fillText(i.toString(), padding - 10, y + 5)
    }

    // Draw hover tooltip
    if (hoveredBar.current) {
      const { x, y, data } = hoveredBar.current
      const tooltipText = `${data.date}: ${data.count} Benutzer`
      ctx.fillStyle = "rgba(0, 0, 0, 0.8)"
      ctx.fillRect(x, y - 30, ctx.measureText(tooltipText).width + 20, 25)
      ctx.fillStyle = "white"
      ctx.fillText(tooltipText, x + 10, y - 10)
    }

    return barPositions
  }, [newUsersLast30Days, hoveredBar])

  return (
    <div className="w-full h-full flex flex-col items-center bg-bg-color text-white flex-grow p-6">
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>

      <div className="w-full max-w-4xl bg-neutral-800 rounded-lg p-6 mb-6">
        <h2 className="text-2xl font-bold mb-4">Total Users</h2>
        <p className="text-4xl font-bold">{totalUsers}</p>
      </div>

      <div className="w-full max-w-4xl bg-neutral-800 rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-4">New Users Growth</h2>
        <canvas
          ref={canvasRef}
          width="800"
          height="400"
          className="w-full h-auto"
        ></canvas>
      </div>

      <div className="w-full max-w-4xl bg-neutral-800 rounded-lg p-6 mt-6">
        <h2 className="text-2xl font-bold mb-4">A/B Test</h2>
        <table className="w-full text-sm text-left text-gray-300">
          <thead className="text-xs uppercase bg-gray-700">
            <tr>
              <th className="px-6 py-3">Group</th>
              <th className="px-6 py-3">Revenue per User</th>
              <th className="px-6 py-3">Conversion Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b bg-gray-800 border-gray-700">
              <td className="px-6 py-4">A: No credits</td>

              <td className="px-6 py-4">
                ${(groupStats.A.totalPayments / groupStats.A.count).toFixed(2)}
              </td>
              <td className="px-6 py-4">
                {((groupStats.A.paidUsers / groupStats.A.count) * 100).toFixed(
                  1
                )}
                %
              </td>
            </tr>
            <tr className="border-b bg-gray-800 border-gray-700">
              <td className="px-6 py-4">B: 20 free credits</td>

              <td className="px-6 py-4">
                $
                {(
                  (groupStats.C.totalPayments - 54) /
                  groupStats.C.count
                ).toFixed(2)}
              </td>
              <td className="px-6 py-4">
                {((groupStats.C.paidUsers / groupStats.C.count) * 100).toFixed(
                  1
                )}
                %
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="w-full max-w-4xl bg-neutral-800 rounded-lg p-6 mt-6">
        <h2 className="text-2xl font-bold mb-4">Recent Cancellations</h2>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-300">
            <thead className="text-xs uppercase bg-gray-700">
              <tr>
                <th className="px-6 py-3">Cancellation Date</th>
                <th className="px-6 py-3">Email</th>
                <th className="px-6 py-3">Cancel Reason</th>

                <th className="px-6 py-3">Cancel Feedback</th>
              </tr>
            </thead>
            <tbody>
              {recentCancellations.map((cancellation) => (
                <tr
                  key={cancellation.id}
                  className="border-b bg-gray-800 border-gray-700"
                >
                  <td className="px-6 py-4">{cancellation.cancellationDate}</td>
                  <td className="px-6 py-4">{cancellation.email}</td>
                  <td className="px-6 py-4">{cancellation.cancelReason}</td>
                  <td className="px-6 py-4">{cancellation.cancelFeedback}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="w-full max-w-4xl bg-neutral-800 rounded-lg p-6 mt-6">
        <h2 className="text-2xl font-bold mb-4">Last 50 New Users</h2>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-300">
            <thead className="text-xs uppercase bg-gray-700">
              <tr>
                <th className="px-6 py-3">Email</th>
                <th className="px-6 py-3">Balance</th>
                <th className="px-6 py-3">Group</th>
                <th className="px-6 py-3">Plan</th>
                <th className="px-6 py-3">Referrer</th>
              </tr>
            </thead>
            <tbody>
              {lastFiftyUsers.map((user) => (
                <tr
                  key={user.id}
                  className="border-b bg-gray-800 border-gray-700"
                >
                  <td className="px-6 py-4">{user.email}</td>
                  <td className="px-6 py-4">{user.balance}</td>
                  <td className="px-6 py-4">{user.group}</td>
                  <td className="px-6 py-4">{user.plan}</td>
                  <td className="px-6 py-4">{user.referrer}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
