import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/analytics"
import "firebase/compat/storage"

import { getStripePayments } from "@stripe/firestore-stripe-payments"

const app = firebase.initializeApp({
  apiKey: "AIzaSyAgS23Ic5TkGWWVE6uLTh0Cm5kGVtfbbPo",
  authDomain: "clarityai.co",
  projectId: "upscaler-89296",
  storageBucket: "upscaler-89296.appspot.com",
  messagingSenderId: "691640351745",
  appId: "1:691640351745:web:e6afff36dff89e6b085400",
  measurementId: "G-5TVSVJ0DZ6",
})

firebase.analytics()

export const auth = app.auth()
export const firestore = app.firestore()
export const firebaseInstance = firebase
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider
export const storage = firebase.storage()

export default app

export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
})

export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
