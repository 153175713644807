import React, { useEffect, useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { firestore, serverTimestamp } from "../../firebase"
import { telegram } from "../../utils/telegram"
import { IconInfo } from "../../components/Icons"
import { uploadToR2 } from "../../components/uploadToR2"
import { useHistory } from "react-router-dom"

export default function StyleImage({
  setCreateImageFunction,
  creditCost,
  setCreditCost,
}) {
  const history = useHistory()
  const { user } = useAuth()

  const [error, setError] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    width: 0,
    height: 0,
    fileUrl: "",
  })
  const [selectedFileRef, setSelectedFileRef] = useState({
    file: null,
    width: 0,
    height: 0,
    fileUrl: "",
  })

  const [style, setStyle] = useState("default")
  const [prompt, setPrompt] = useState("")
  const [mode, setMode] = useState("upscale")

  const [styleStrengthToolTip, setStyleStrengthToolTip] = useState(false)
  const [structureStrengthToolTip, setStructureStrengthToolTip] =
    useState(false)

  const [styleStrength, setStyleStrength] = useState(0)
  const [structureStrength, setStructureStrength] = useState(0)

  const [styleTransferVersion, setStyleTransferVersion] = useState("v2")

  const handleFileChange = (event) => {
    setError(null)
    const file = event.target.files[0]

    const fileUrl = URL.createObjectURL(file)

    const img = new Image()

    img.onload = () => {
      setSelectedFile({
        file: file,
        width: img.width,
        height: img.height,
        fileUrl: fileUrl,
      })
    }
    img.src = fileUrl
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()

    const files = e.dataTransfer.files

    if (files.length > 0) {
      const file = files[0]

      const fileUrl = URL.createObjectURL(file)

      const img = new Image()

      img.onload = () => {
        setSelectedFile({
          file: file,
          width: img.width,
          height: img.height,
          fileUrl: fileUrl,
        })
      }

      img.src = fileUrl
    }
  }

  const handleFileChangeRef = (event) => {
    setError(null)
    const file = event.target.files[0]

    const fileUrl = URL.createObjectURL(file)

    const img = new Image()

    img.onload = () => {
      setSelectedFileRef({
        file: file,
        width: img.width,
        height: img.height,
        fileUrl: fileUrl,
      })
    }
    img.src = fileUrl
  }

  const handleDragOverRef = (e) => {
    e.preventDefault()
  }

  const handleDropRef = (e) => {
    e.preventDefault()

    const files = e.dataTransfer.files

    if (files.length > 0) {
      const file = files[0]

      const fileUrl = URL.createObjectURL(file)

      const img = new Image()

      img.onload = () => {
        setSelectedFileRef({
          file: file,
          width: img.width,
          height: img.height,
          fileUrl: fileUrl,
        })
      }

      img.src = fileUrl
    }
  }

  const createImageDocument = async () => {
    setError(null)
    if (!user) {
      setError("Register to get tokens.")
      return
    }

    if (user.balance <= 0 || user.balance < creditCost) {
      setError("Not enough tokens!")
      history.push("/pricing")
      return
    }

    if (!selectedFile.file) {
      setError("No image selected!")
      return
    }

    if (!selectedFileRef.file || !selectedFile.file) {
      setError(
        "In Style-Transfer mode you need an input image and an style image."
      )
      return
    }

    const downloadURL = await uploadToR2(selectedFile.file, true)

    let downloadURLRef = ""

    if (selectedFileRef.file !== null) {
      downloadURLRef = await uploadToR2(selectedFileRef.file, true)
    }

    const credits = user.balance - creditCost
    try {
      await firestore.collection("users").doc(user.id).update({
        balance: credits,
      })
    } catch (error) {
      telegram("Create, createImageDocument, error: " + error.message)
      setError("Error while updating tokens!")
      return null
    }

    const imageData = {
      userId: user.id,
      createdAt: serverTimestamp(),
      status: "created",
      mode: "style-transfer",
      inputImage: downloadURL,
      styleImage: downloadURLRef,
      style: style,
      styleStrength: styleStrength,
      structureStrength: structureStrength,
      prompt: prompt,
      creditCost: creditCost,
      width: selectedFile.width,
      height: selectedFile.height,
      styleTransferVersion: styleTransferVersion,
    }

    try {
      const imagesCollectionRef = firestore
        .collection("users")
        .doc(user.id)
        .collection("images")

      const docRef = await imagesCollectionRef.add(imageData)

      return docRef.id
    } catch (error) {
      telegram("Create.js, createImageDocument, error: " + error.message)
      return null
    }
  }

  useEffect(() => {
    setCreateImageFunction(
      () => () =>
        createImageDocument(
          user,
          selectedFile,
          selectedFileRef,
          creditCost,
          history,
          style,
          prompt,
          setError
        )
    )
  }, [
    user,
    selectedFile,
    selectedFileRef,
    creditCost,
    history,
    style,
    prompt,
    setCreateImageFunction,
  ])

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectedFile({ file: null })
  }

  const handleDeleteClickRef = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectedFileRef({ file: null })
  }

  return (
    <div className="w-full pl-3 pr-1 py-2 pb-3 relative md:h-[calc(100vh-194px)] md:overflow-y-auto md:max-w-xs md:pr-3 border-neutral-700">
      <div
        className="file-uploader"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      ></div>

      <p className="font-bold mt-2 mb-2">Input image</p>
      <div
        className={
          selectedFile.file
            ? "w-full max-h-64 relative"
            : "flex flex-col items-center justify-center w-full h-32 border border-neutral-700 border-dashed rounded-lg cursor-pointer bg-neutral-800 hover:bg-neutral-700"
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <label htmlFor="dropzone-file" className="w-full h-full">
          {selectedFile.file ? (
            <div className="relative">
              <img
                className="max-h-64 rounded-lg cursor-pointer bg-neutral-800 hover:bg-gray-100"
                src={selectedFile.fileUrl}
                alt="Vorschau"
              />
              <div
                className="absolute text-xs px-2 left-1 top-1 w-14 bg-neutral-800/70 p-1 rounded-lg font-bold cursor-pointer hover:bg-opacity-80 hover:text-black"
                onClick={handleDeleteClick}
              >
                Delete
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <>
                <svg
                  className="w-8 h-8 mb-4 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>

                <p className="mb-2 px-2 text-sm text-white text-center">
                  Click to upload or drop your image
                </p>
              </>
            </div>
          )}
        </label>
        <input
          type="file"
          id="dropzone-file"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      <div>
        <div
          className="file-uploaderRef"
          onDragOver={handleDragOverRef}
          onDrop={handleDropRef}
        ></div>

        <p className="font-bold mt-6 mb-2">Style image</p>
        <div
          className={
            selectedFileRef.file
              ? "w-full max-h-64 relative"
              : "flex flex-col items-center justify-center w-full h-32 border border-neutral-700 border-dashed rounded-lg cursor-pointer bg-neutral-800 hover:bg-neutral-700"
          }
          onDragOver={handleDragOverRef}
          onDrop={handleDropRef}
        >
          <label htmlFor="dropzone-fileRef" className="w-full h-full">
            {selectedFileRef.file ? (
              <div className="relative">
                <img
                  className="max-h-64 rounded-lg cursor-pointer bg-neutral-800 hover:bg-gray-100"
                  src={selectedFileRef.fileUrl}
                  alt="preview"
                />
                <div
                  className="absolute text-xs px-2 left-1 top-1 w-14 bg-neutral-800/70 p-1 rounded-lg font-bold cursor-pointer hover:bg-opacity-80 hover:text-black"
                  onClick={handleDeleteClickRef}
                >
                  Delete
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <>
                  <svg
                    className="w-8 h-8 mb-4 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>

                  <p className="mb-2 px-2 text-sm text-white text-center">
                    Click or drag and drop your image
                  </p>
                </>
              </div>
            )}
          </label>

          <input
            type="file"
            id="dropzone-fileRef"
            className="hidden"
            onChange={handleFileChangeRef}
          />
        </div>
      </div>

      <p className="font-bold mt-6 mb-2">Prompt (optional)</p>
      <textarea
        id="prompt"
        type="text"
        rows="3"
        className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />

      <div>
        <div>
          <div className="flex items-center mt-6">
            <p className="font-bold my-2 mr-2">Style strength</p>
            <div
              className="cursor-pointer"
              onMouseEnter={() => setStyleStrengthToolTip(true)}
              onMouseLeave={() => setStyleStrengthToolTip(false)}
            >
              <IconInfo />
            </div>
          </div>
          {styleStrengthToolTip && (
            <div className="absolute z-10 w-52 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
              Adjust the strength of the style to get applied to your input
              image.
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          )}

          <div className="grid items-center">
            <input
              className="w-full h-2 bg-gray-200 rounded-lg cursor-pointer"
              type="range"
              min="-10"
              max="10"
              step="1"
              value={styleStrength}
              onChange={(event) =>
                setStyleStrength(parseFloat(event.target.value))
              }
            />
            <span className="justify-self-end">{styleStrength}</span>
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <p className="font-bold my-2 mr-2">Structure strength</p>
            <div
              className="cursor-pointer"
              onMouseEnter={() => setStructureStrengthToolTip(true)}
              onMouseLeave={() => setStructureStrengthToolTip(false)}
            >
              <IconInfo />
            </div>
          </div>
          {structureStrengthToolTip && (
            <div className="absolute z-10 w-52 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
              Adjust the strength of keeping the structure of your input image.
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          )}
          <div className="grid items-center">
            <input
              className="w-full h-2 bg-gray-200 rounded-lg cursor-pointer"
              type="range"
              min="-10"
              max="10"
              step="1"
              value={structureStrength}
              onChange={(event) =>
                setStructureStrength(parseFloat(event.target.value))
              }
            />
            <span className="justify-self-end">{structureStrength}</span>
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <p className="font-bold my-2 mr-2 mt-2">Version</p>
          </div>
          <select
            className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
            value={styleTransferVersion}
            onChange={(event) => setStyleTransferVersion(event.target.value)}
          >
            <option value="v2">v2</option>
            <option value="v1">v1</option>
          </select>
        </div>
      </div>

      {selectedFile.file && (
        <div className="flex flex-col items-center justify-center my-4 py-1 bg-neutral-800 border border-neutral-700 rounded-lg text-sm text-neutral-400">
          <p>Will cost 5 tokens</p>
        </div>
      )}

      {error && (
        <div
          className="flex items-center p-4 mb-4 text-sm border rounded-lg bg-gray-800 text-red-400 border-red-800"
          role="alert"
        >
          <div> {error}</div>
        </div>
      )}
    </div>
  )
}
