import axios from "axios"

// Lesen Sie die Werte aus den Umgebungsvariablen
const chatId = process.env.REACT_APP_TELEGRAM_CHAT_ID
const botToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN

export const telegram = async (message) => {
  try {
    const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`

    await axios.post(apiUrl, {
      chat_id: chatId,
      text: "ClarityAI, frontend, " + message,
    })
  } catch (error) {
    console.error("Error while sending error notification:", error)
  }
}
