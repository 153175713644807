import { telegram } from "../utils/telegram"

export async function uploadToR2(file) {
  if (!file) {
    const error = "File is undefined or null"
    await telegram(error)
    throw new Error(error)
  }

  const formData = new FormData()
  formData.append("file", file)

  try {
    const response = await fetch(
      "https://upload-image-endpoint-oak26mtdga-ey.a.run.app/",
      {
        method: "POST",
        body: formData,
      }
    )

    if (!response.ok) {
      const error = `Upload failed: ${response.statusText}`
      await telegram(error)
      throw new Error(error)
    }

    const result = await response.text()
    return result.replace("File uploaded successfully: ", "")
  } catch (error) {
    await telegram(`Upload error: ${error.message}`)
    throw error
  }
}
