import { useState, useLayoutEffect, useMemo } from "react"

export default function useRefDimensions(ref, options = { debug: false }) {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  useLayoutEffect(() => {
    const updateDimensions = () => {
      if (ref?.current) {
        const { width, height } = ref.current.getBoundingClientRect()

        if (options?.debug) {
          console.log("[DEBUG] useRef", options?.debug && options.debugId, {
            width,
            height,
          })
        }
        setDimensions({ width, height })
      }
    }

    // Initial dimensions
    updateDimensions()

    // Observer for resize
    const observer = new ResizeObserver(updateDimensions)

    if (ref?.current) {
      observer.observe(ref.current)
    }

    // Event listener for window resize
    window.addEventListener("resize", updateDimensions)

    return () => {
      if (ref?.current) {
        observer.unobserve(ref.current)
      }
      window.removeEventListener("resize", updateDimensions)
    }
  }, [ref])

  // Memoize width value
  const memoisedDims = useMemo(
    () => ({
      width: Math.floor(dimensions.width),
      height: Math.floor(dimensions.height),
    }),
    [dimensions.width, dimensions.height]
  )

  return {
    ...memoisedDims,
  }
}
